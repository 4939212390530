// import axios from 'axios';

// export const instance = axios.create({
//     api: 'https://support.com/api/',
//     headers: {
//         'Accept': 'application/json',

//     },
// });

import axios from 'axios';
import { useHistory } from 'react-router-dom';

const Token = localStorage.getItem('token');
// const api = 'http://localhost:8080/api/';  // local
// const api = 'https://backend.momsfoood.com/api/';  // production
const api = 'https://backend-staging.momsfoood.com/api/';  //staging
    console.log(Token);
    
const createAxiosInstance = (token) => {
    const instance = axios.create({
        baseURL: `${api}/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'x-token': token
        }
    });

    // Add a response interceptor
    instance.interceptors.response.use(
        (response) => response, // If the response is successful, just return it
        (error) => {
            console.log(error);

            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.href = '/auths/auth-login';
            }
            return Promise.reject(error); // Return the error if it's not 401
        }
    );

    return instance;
};

export const instance = createAxiosInstance(Token);

export const updateToken = (newToken) => {
    instance.defaults.headers['Authorization'] = `Bearer ${newToken.trim()}`;
    instance.defaults.headers['x-token'] = newToken;
    localStorage.setItem('token', newToken); // Update the token in localStorage
};

export default instance;


